import { Injectable, Injector } from '@angular/core';
import { HttpEvent,HttpResponse, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { JwtService } from '../services';
import { map, catchError } from 'rxjs/operators';
import { UserTypeConstants,authKey } from "src/app/core/constants/userType.constant";


@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(private jwtService: JwtService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headersConfig = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin' : 'https://api.razorpay.com',
      'Origin' : 'https://api.razorpay.com',
      'Accept': 'application/json',
      'appinventiv':'digivive',
    };

    const token = this.jwtService.getToken();
    if (token) {
      if(localStorage.getItem('sessionId')){
        headersConfig['Authorization'] = localStorage.getItem('sessionId');  
      } else {
        headersConfig['Authorization'] = `${token}`;
      }
    }

    if (req.url.substring(req.url.lastIndexOf('/') + 1)=="signupnexg") {
      headersConfig['Authorization'] = authKey.defaultAuthKey;  
    }

    // if(req.url == 'https://apps.digivive.com/nexgtv_whitelabled_web/signupnexg') {
    //   headersConfig['Authorization'] = 'de6ed43f6c08ecf56536f8abcc812701';  
    // }

    const request = req.clone({ setHeaders: headersConfig });



    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // event.body.error_code = '220';
            if(event.body.error_code == '220') {
              localStorage.removeItem('isAuthenticated');
              localStorage.removeItem('userInfo');
              localStorage.removeItem('sessionId');
              localStorage.removeItem('playListId');
              localStorage.removeItem('parentControl');
              localStorage.removeItem('debug');
              // window.location.reload();
            }
          }
          return event;
      }));

   }

}
