
<div class="campaign-banner" (click)="redirectUrl(content)">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <!-- <image-manipulation [img]="{{imgType}}"></image-manipulation> -->
                <img class="grid_item" src="{{showImg}}"  [ngClass]="{'bannerWidth': bannerWidth }" >
            </div>
        </div>
    </div>
</div>
<!-- <div *ngIf="data.landing_page != '' || data.landing_page == '__url' ">

</div>
<div>
    {{data | json}}
</div> -->



 <!-- Cancel subscription -->
 <ng-template #content let-modal>
    <app-login></app-login>
</ng-template>
