<section class="my_subscription">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h4 class="accmain-head">Subscription</h4>

                <!-- Choose plan -->

                <div class="_choose_plan">
                    <ul>
                        <li class="active">
                            <p>Choose Plan</p>
                            <span class="_S_circle"></span>
                        </li>
                        <li class="make_payment">
                            <p>Make Payment</p>
                            <span class="_S_circle"></span>
                        </li>
                        <li>
                            <p>Confirmation</p>
                            <span class="_S_circle"></span>
                        </li>
                    </ul>
                </div>

                <!-- <button class="btn btn-primary" (click)="proceed()">Test razorpay api</button> -->

                <!-- Apply coupon -->
                <div class="_subscription_p text-center coupon_code">
                    <div class="_subscription_p_d ">

                        <p>Please purchase any one of the following packs to watch this Movie/Series/Content and more.
                        </p>
                        <div class="_s_promo_code">
                            <!-- <form class="_promo_form" [formGroup]="addPromoForm" (ngSubmit)="applypromoCode()">
                                <div class="form-group">
                                    <input type="text" formControlName="promocode" autocomplete="off"
                                        class="form-control" placeholder="Have a code?" required>
                                    <button class="_t_btn">Apply now</button>
                                </div>
                                <p class="error error-height">
                                    <span style="color: red;" *ngIf="promocode_error != ''"> {{promocode_error}}</span>
                                </p>

                            </form> -->
                            <!-- <form class="_btn_form">
                                <button class="_p_btn" (click)="continueButton()">Continue</button>
                            </form> -->

                        </div>
                    </div>
                </div>

                <!-- pack list -->
                <div class="nopack" *ngIf="groupPack.length == 0">{{noPackFound}}</div>

                <div *ngIf="groupPack.length > 0">

                    <div *ngFor="let group of groupPack;let indexJS=index;" class="_subscription_p">

                        <!-- group side bar start -->
                        <div *ngIf="countObjectKey(group) > 1" class="_subscription_amount" id="_subscription_amount">
                            <h2>{{group.name}}</h2>
                            <span class="subscription_text_bg">
                                {{group.PMV_CHANNEL_LIST}}
                            </span>

                            <ul>
                                <li *ngFor="let gDesc of group.GROUP_DESCRIPTION">
                                    <i class="fa fa-check" aria-hidden="true"></i>
                                    <p>{{gDesc}}</p>
                                </li>
                            </ul>
                        </div>
                        <!-- group side bar end -->


                        <!-- list of group packs start -->
                        <div class="_subscription_p_d">
                            <div class="_subscription_package_nouse">
                                <div class="_subscription_package" [ngClass]="{_subscription_package_list: catalogue_settings && catalogue_settings.layout_type == 'list' }" *ngFor="let pack of group.packs;let index=index;">
                                    <div class="_option_box_shadow "
                                        *ngIf=" countObjectKey(pack) > 0 && pack.PM_PRICE != ''">

                                        <input type="radio" name="rentSelectedCode" [value]="pack.PM_CHARGECODE"
                                            [checked]="pack.PM_CHARGECODE == fristPack.PM_CHARGECODE"
                                            (change)="onRentItemChange($event.target.value, pack,indexJS)">
                                        <div class="_amount_data">
                                            <span class="plan_bg">{{pack.PMV_VAL_DES}}</span>
                                            <div class="_img_tik">
                                                <img src="/assets/img/right_tik.png">
                                            </div>
                                            <div class="_plan_list">
                                                <div class="_paln_price_align">
                                                    <div class="_r_value _off_price " *ngIf="pack.PCH_IS_DISCOUNT > 0">
                                                        <img src="/assets/img/rupees_light.png">
                                                        <h2>
                                                            {{pack.PCH_IS_DISCOUNT}}
                                                        </h2>
                                                    </div>
                                                    <div class="_r_value">
                                                        <img src="/assets/img/rupees.png">
                                                        <h2>
                                                            {{pack.PM_PRICE}}
                                                        </h2>
                                                    </div>
                                                </div>
                                                <h6>
                                                    Validity : {{pack.PM_VALIDITY}}
                                                    {{pack.PM_VALIDITY == '1' ? 'day' :
                                                    'days'}}
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="_s_promo_code pull-right">
                                <form class="_btn_form">
                                    <button class="mr15 _p_btn" [disabled]="selectedIndex != indexJS"
                                        (click)="continueButtons()">Continue </button>
                                </form>
                            </div>


                            <!-- Select_final plan -->
                            <div *ngFor="let pack of group.packs;let index=index;">
                               
                                <div *ngIf="rentContinuePressed && selectedRentChargeCode == pack.PM_CHARGECODE">
                                   
                                    <div class="_subscription_payment">

                                        <div class="_subscription_second" [ngClass]="{_subscription_second_list: catalogue_settings && catalogue_settings.layout_type == 'list' }">
                                            <div class="_plan_selection">
                                                <div class="_amount_data">
                                                    <span class="plan_bg">{{pack.PMV_VAL_DES}}</span>
                                                    <div class="_plan_list confirm_plan_margin_top">
                                                        <div class="_r_value">
                                                            <img src="/assets/img/rupees.png">
                                                            <h2>
                                                                {{pack.PM_PRICE}}
                                                            </h2>
                                                        </div>
                                                        <h6>
                                                            Validity : {{pack.PM_VALIDITY}}
                                                            {{pack.PM_VALIDITY == '1' ? 'day' :
                                                            'days'}}
                                                        </h6>

                                                    </div>
                                                    <p>
                                                        You will be automatically charged ₹ {{pack.PM_PRICE}} after every  {{pack.PM_VALIDITY}} days
                                                    </p>
                                                    <p >

                                                        <i *ngIf="isCollapsed" class="mt-1 fa fa-angle-down"
                                                            (click)="collapse.toggle()"
                                                            [attr.aria-expanded]="!isCollapsed"
                                                            aria-controls="collapseExample" style="font-size:24px"></i>

                                                        <i *ngIf="!isCollapsed" class="mt-1 fa fa-angle-up"
                                                            (click)="collapse.toggle()"
                                                            [attr.aria-expanded]="!isCollapsed"
                                                            aria-controls="collapseExample" style="font-size:24px"></i>
                                                    </p>
                                                    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" >
                                                        <div class="amt-collaps">
                                                            <div>
                                                                <span>Pack Price</span>
                                                                
                                                                <span class="pull-right">₹{{pack.PM_PRICE}}</span>
                                                            </div>
                                                            <div *ngIf="is_applied_coupon">
                                                                <span> Coupon Discount</span>
                                                                
                                                                <span class="pull-right">₹{{coupon_discount}}</span>
                                                            </div>
                                                            <div>
                                                                <span>Amount Payable</span>
                                                                <span class="pull-right">₹{{final_price ? final_price : pack.PM_PRICE}}</span>
                                                            </div>
                                                        </div>



                                                    </div>

                                                    <!-- <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
                                                        <ngb-panel title="Simple">
                                                            <ng-template ngbPanelContent>
                                                               
                                                                synth nesciunt you probably haven't heard of them
                                                                accusamus
                                                                labore sustainable VHS.
                                                            </ng-template>
                                                        </ngb-panel>
                                                    </ngb-accordion> -->
                                                </div>
                                            </div>

                                        </div>


                                    </div>
                                </div>
                            </div>

                            <!-- payment options -->

                            <div *ngFor="let pack of group.packs;let index=index;">
                                <div *ngIf=" countObjectKey(pack) > 0  && pack.PM_PRICE != '' ">

                                    <div *ngIf="selectedRentChargeCode == pack.PM_CHARGECODE && rentContinuePressed">
                                        <div class="coupone_text_bg" data-toggle="modal" data-target="#couponModal"
                                            *ngIf="!is_applied_coupon">
                                            <i class="fa fa-certificate"></i> Apply Coupon/Voucher <i
                                                class="mt-1 pull-right fa fa-angle-right"></i>
                                        </div>
                                        <p class="error error-height">
                                            <span style="color: red;" *ngIf="promocode_error != ''"> {{promocode_error}}</span>
                                        </p>
                                        <div class="coupone_text_bg" *ngIf="is_applied_coupon">
                                            <i class="fa fa-check"></i> Code {{coupon_code}} applied successfuly <i
                                                class="mt-1 pull-right fa fa-times" data-toggle="modal"
                                                data-target="#couponModal" (click)="removeAppliedCode()"></i>
                                        </div>
                                        <div class="_subscription_payment _select_option_m">

                                            <p class="choose_payment"
                                                *ngIf="pack.PACK_TYPE && pack.PACK_TYPE.length >= 1">
                                                <!-- Please select a payment method -->
                                            </p>
                                            <div class="_subscription_card">
                                                <div class="payment_detail">
                                                    <form (submit)="placeOrder()">
                                                    <!-- <form (submit)="proceed()"> -->

                                                        <div class="form-group">
                                                            <div class="form-group"
                                                               
                                                                *ngFor="let paymode of pack.PAYMENT_MODES" [hidden]="paymode.PACK_TYPE != 'RAZORPAY' && catalogue_settings.is_sdk == 1 ">
                                                                <label>
                                                                    <input type="radio" [id]="paymode.PM_CHARGECODE"
                                                                        name="selectedRentPayment"
                                                                        class="selectedRentPayment"
                                                                        (change)="onPaymentSelected(paymode.PM_PRICE, paymode, paymode.PM_VALIDITY)"
                                                                        checked="checked">
                                                                    <div class="_img_tik">
                                                                        <img src="/assets/img/right_tik.png">
                                                                    </div>
                                                                    {{paymode.PCH_PUR_MODE_DESC}}
                                                                   
                                                                    <!-- <p class="payment_mode_info" *ngIf="paymode.SDK_RENEWFLAG == 'Y'">
                                                                        <input type="checkbox" name="SDK_RENEWFLAG" id="renew_flag" checked>
                                                                        {{paymode.PAYMENT_MODE_TEXT}}</p> -->
                                                                    <img class="paymode"
                                                                        *ngIf="strToLower(paymode.PCH_PUR_MODE_DESC)"
                                                                        src="{{paymode.PCH_IMG}}">
                                                                    <!-- <img class="paymode"
                                                                        *ngIf="strToLower(paymode.PCH_PUR_MODE_DESC) == 'upi'"
                                                                        src="{{paymode.PCH_IMG}}">
                                                                    <img class="paymode"
                                                                        *ngIf="strToLower(paymode.PCH_PUR_MODE_DESC) == 'cards/nb/others'"
                                                                        src="{{paymode.PCH_IMG}}">
                                                                    <img class="paymode"
                                                                        *ngIf="strToLower(paymode.PCH_PUR_MODE_DESC) == 'razorpay'"
                                                                        src="{{paymode.PCH_IMG}}">

                                                                    <img class="paymode"
                                                                        *ngIf="strToLower(paymode.PCH_PUR_MODE_DESC) == 'credit/debit/upi'"
                                                                        src="{{paymode.PCH_IMG}}"> -->
                                                                      
                                                                </label>
                                                               
                                                                <p class="payment_mode_info" *ngIf="paymode.SDK_RENEWFLAG == 'Y'">
                                                                    <input type="checkbox" name="SDK_RENEWFLAG" id="renew_flag" checked>
                                                                    You will be automatically charged  ₹ {{paymode.PM_PRICE}} after every {{paymode.PM_VALIDITY}} days</p>
                                                            
                                                               
                                                            </div>
                                                            <div *ngIf="!selectedRentPayment" class="help-block error">
                                                                {{details_error}}</div>
                                                        </div>


                                                        <div class="_pay_btn_s">
                                                            <button class="_p_btn_custom"
                                                                *ngIf="selectedRentChargeCode != ''  && rentContinuePressed"
                                                                [disabled]="payButtonText != 'PAY NOW'">{{payButtonText}}</button>
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>
                        <!-- list of group packs end -->
                        <!-- <span style="color: #fff;">test {{groupPack.length}} {{indexJS}}</span> -->
                        <div class="_or" *ngIf="grouplength&&((grouplength-1) >indexJS)">
                            <p>or</p>
                        </div>

                    </div>

                </div>



            </div>
        </div>
    </div>

    <!--Add promocode Success-->
    <a #add_promocodesuccess data-toggle="modal" data-target="#add_promocodesuccess"></a>
    <div class="modal auth" id="add_promocodesuccess" data-backdrop="static">
        <div class="modal-dialog">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" #closePromoButton>&times;</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div class="success-add">
                        <img src="../../../assets/img/success.png">
                        <p>Promocode has been applied successfully.</p>
                    </div>

                    <div class="action-btn">
                        <!-- <div>
                            <button type="button" class="btn btn-primary yes" (click)="goToMyacoount()">Ok</button>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Add promocode Success-->


    <!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#couponModal"
        data-whatever="@mdo">Open modal for @mdo</button> -->


    <div class="modal fade" id="couponModal" tabindex="-1" role="dialog" aria-labelledby="couponModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="couponModalLabel">Coupon Code</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body coupon-body">
                    <form>
                        <div class="form-group">
                            <input type="text" name="manual_coupon_code" [(ngModel)]="manual_coupon_code" autocomplete="off" class="form-control"
                                placeholder="Have a code?" required>
                            <button class="_t_btn" data-dismiss="modal" (click)="applyCouponManual(manual_coupon_code)">Apply now</button>
                        </div>

                        <div class="coupone_text_bg">
                            Available Offers
                        </div>

                    </form>
                    <div class="row mt-4" *ngFor="let coupon of coupon_list">
                        <div class="col-8">
                            {{coupon.coupon_code}}
                        </div>
                        <div class="col-4">
                            <button class="_t_btn" (click)="applyNow(coupon)" data-dismiss="modal">Apply now</button>
                        </div>
                        <div class="col-12">
                            <!-- Offer is applicable for upi mode only -->
                            {{coupon.coupon_summary.display1}}
                        </div>
                        <div class="col-12">
                            <!-- You will save Rs 40 on your half yearly plan with this code -->
                            {{coupon.coupon_summary.display2}}
                        </div>
                        <hr>
                    </div>
                  
                    <!-- <div class="row mt-4">
                        <div class="col-8">
                            NYEAR60
                        </div>
                        <div class="col-4">
                            <button class="_t_btn" (click)="applyNow()" data-dismiss="modal">Apply now</button>
                        </div>
                        <div class="col-12">
                            Offer is applicable for upi mode only
                        </div>

                    </div> -->
                    <hr>
                </div>
                <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Send message</button>
      </div> -->
            </div>
        </div>
    </div>


    <div class="novopay-response-modal modal fade" role="dialog" [ngClass]="{'show': showModal}" id="response-modal"
        [ngStyle]="{'display': 'none'}">
        {{response}}
    </div>

    <div class="novopay-response-modal modal fade" role="dialog" [ngClass]="{'show': showModal}" id="razorpay-response"
        [ngStyle]="{'display': 'none'}">
        {{razorpayResponse}}
    </div>
</section>