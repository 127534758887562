import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { routes } from 'src/app/app.routing';
import { DynamicTabComponent } from 'src/app/views/dynamic-tab/dynamic-tab.component';
import { CatlougeTypeConstants } from '../constants';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {
  currentRoute: any;
  currentSlugName: any;
  activeMenu: any;
  slugName: any;
  headerTabs: string;

  constructor(public location: Location, public router: Router, public userService: UserService) {

    //   this.userService.activeMenu.subscribe((activeMenu) => {
    //     this.activeMenu = activeMenu;
    //     document.getElementById('navbarsExample04').classList.remove("show");
    // });
    //alert(location.path());
     if(location.path()==''){
       localStorage.removeItem('slugName');
     }

    this.slugName = localStorage.getItem('slugName');
    this.currentSlugName = this.userService.currentSlugName;

    router.events.subscribe(val => {
      if (location.path() != "") {
        this.currentRoute = location.path();
      } else {
        this.currentRoute = "/";
      }
    })

    // this.headerTabs = localStorage.getItem('tabs');
    // if(this.headerTabs.length == 0){
    //   this.Init();
    // }
  }

  Init() {

    return new Promise<void>((resolve, reject) => {

      let dynamicRoute = [];

      let tabDataParams = {
        "catlogue": CatlougeTypeConstants.catlogue,
        // "tab_id":"",
        // "category_id":"",
        // "sub_category_id":"",
        // "sub_sub_category_id":"6110dafdaa47a5710906460c",
        "call": "gettabs"
      }

      let dynamicTabRes = this.userService.convertoJSONToUrlEncoded(tabDataParams);

      this.userService.dynamicTab(dynamicTabRes).subscribe((tabRes) => {

        var base64decodeData =  atob(tabRes.filter_type);
        localStorage.setItem('filterTypeData', base64decodeData);
        
        // if (this.slugName == undefined || this.slugName == null || this.slugName == "") {
        //   this.slugName = tabRes.result[0].slug;
        // }
        if (tabRes.result && tabRes.result.length > 0) {
          tabRes.result.forEach((tabResData, index) => {
            dynamicRoute.push({
              path: `${tabResData.slug}`,
              component: DynamicTabComponent
            })
            localStorage.setItem('tabs', tabRes.result);
            if (index >= tabRes.result.length - 1) {
              // console.log(dynamicRoute)
              // console.log(routes);
              let finalRoute = [...dynamicRoute, ...routes];
              // console.log(finalRoute);
              // this.userService.updateSelectedMenu(tabRes.result[0].slug);
              this.userService.setCurrentSlugName(tabRes.result[0].slug);
              localStorage.setItem('continuewatching', tabRes.result[0].slug);
              if (this.slugName) {
                // localStorage.setItem('currentSlugName',this.currentSlugName);
                this.userService.updateSelectedMenu(this.slugName);
                this.userService.setCurrentSlugName(this.slugName);
              } else {
                this.slugName = tabRes.result[0].slug;
                // localStorage.setItem('currentSlugName',tabRes.result[0].slug);
                this.userService.updateSelectedMenu(tabRes.result[0].slug);
                this.userService.setCurrentSlugName(tabRes.result[0].slug);
              }

              console.log(this.router);
              this.router.resetConfig(finalRoute);
              console.log("AppInitService.init() called");
              console.log(this.router.url);
              this.router.navigateByUrl(this.currentRoute);
              // this.router.navigateByUrl(this.currentSlugName);
              resolve();

            }

          });
        }
      })
    })
  }
}
