import { Injectable } from '@angular/core';

@Injectable()
export class JwtService {

  getToken(): String {
    // return 'de6ed43f6c08ecf56536f8abcc812701';
    return '60194480f127015d9011475a';
    // return '5f5b20d1b9124f76ab3f56d4';
    // return window.localStorage['jwtToken'];
  }

  saveToken(token: String) {
    window.localStorage['jwtToken'] = token;
  }

  destroyToken() {
    window.localStorage.removeItem('jwtToken');
  }

}
