import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { JwtService } from './jwt.service';
import { catchError, retry } from 'rxjs/operators';

@Injectable()
export class ApiService {
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  constructor(
    private http: HttpClient,
    private jwtService: JwtService,
    public router:Router,
    public activatedRoute:ActivatedRoute,

  ) { }

  private formatErrors(error: any) {
    
    if(error.status === 500) {
      //window.location.replace('500')
    }
    return throwError(error.error);
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    
    return this.http.get(`${environment.api_url}${path}`)
      .pipe(retry(1), catchError(this.formatErrors));
  }

  put(path: string, body: Object = {}): Observable<any> {
    return this.http.put(
      `${environment.api_url}${path}`,
      body
    ).pipe(catchError(this.formatErrors));
  }

  post(path: string, body: Object = {}): Observable<any> {
   
    return this.http.post(
      `${environment.api_url}${path}`,
      body
    ).pipe(catchError(this.formatErrors));
  }


  delete(path): Observable<any> {
    return this.http.delete(
      `${environment.api_url}${path}`
    ).pipe(catchError(this.formatErrors));
  }

  postFile(path: string, body): Observable<any> {
    return this.http.post(
      `${environment.api_url}${path}`,
      JSON.stringify(body)
    ).pipe(catchError(this.formatErrors));
  }

  playUrl(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.api_url}${path}`)
    .pipe(retry(1), catchError(this.formatErrors));
  }

  // checkAllowedDevices(path: string, body: Object = {}): Observable<any> {
   
  //   return this.http.post(
  //     `${environment.play_url}${path}`,
  //     body
  //   ).pipe(catchError(this.formatErrors));
  // }
}
