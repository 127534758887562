<!-- <p style="color: white;">category works!</p> -->
<section class="_custom_paren_img mini_slider wow animate__animated animate__fadeInUp"
    [ngClass]="{'assets_hover': assets_hover }">

    <div *ngIf="data.sub_categories.length == 0">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="_no_data_f">
                        <h1>No Data Found</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="_custom_cont" *ngIf="data.sub_categories.length>0">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="i_head_c">
                        <h4>{{data.category_name}}</h4>
                        <a *ngIf="data.sub_categories && data.sub_categories.length > 4" (click)="viewall(data)">View
                            all</a>
                        <!-- <a *ngIf="data.assets && data.assets[0].type == 'livetv' && data.assets.length > 4" (click)="viewall(data)">View all</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="_custom_grid_img mb40" [ngClass]="{'assets_hover': assets_hover }">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="owl-carousel four_slides owl-theme {{thumbnailClass}}"
                        [ngClass]="{'_less_four':data.assets.length <= 4 }">
                        <owl-carousel-o [options]="owlOption">

                            <ng-container *ngFor="let item of data.sub_categories; let i=index">
                                <ng-template carouselSlide>
                                    <div class="item" (click)="redirectUrl(item, content, jwplaydom, $event)">
                                        <div class="_watching_img1" (mouseenter)="item_hover_enter()"
                                            (mouseleave)="item_hover_leave()">
                                            <div class="p_g_i">
                                               
                                                <image-manipulation *ngIf="thumbnailType == 'portrait'" [img]="isCloudFrontType == false ? item.thumbnail_portrait || item.thumbnail : item.thumbnail_portrait_cloudfront_url || item.thumbnail_cloudfront_url" [params]="assetImgParam" [notFound]="notFoundImg"></image-manipulation>
                                                <image-manipulation *ngIf="thumbnailType == 'landscape'" [img]="isCloudFrontType == false ? item.thumbnail : item.thumbnail_cloudfront_url" [params]="assetImgParam" [notFound]="notFoundImg"></image-manipulation>
                                                <image-manipulation *ngIf="thumbnailType == 'circular'" [img]="isCloudFrontType == false ? item.thumbnail_square || item.thumbnail : item.thumbnail_square_cloudfront_url || item.thumbnail_cloudfront_url" [params]="assetImgParam" [notFound]="notFoundImg"></image-manipulation>
                                                <image-manipulation *ngIf="thumbnailType == 'stretched-landscape'" [img]="isCloudFrontType == false ? item.thumbnail_stretched_landscape || item.thumbnail : item.thumbnail_stretched_landscape_cloudfront_url || item.thumbnail_cloudfront_url" [params]="assetImgParam" [notFound]="notFoundImg"></image-manipulation>
                                                <image-manipulation *ngIf="thumbnailType == 'square'" [img]="isCloudFrontType ==false ?item.thumbnail_square || item.thumbnail : item.thumbnail_square_cloudfront_url || item.thumbnail_cloudfront_url" [params]="assetImgParam" [notFound]="notFoundImg"></image-manipulation>
                                                <image-manipulation *ngIf="thumbnailType == 'enlarged-landscape'" [img]="isCloudFrontType == false ? item.thumbnail || item.thumbnail : item.thumbnail_cloudfront_url" [params]="assetImgParam" [notFound]="notFoundImg"></image-manipulation>
                                                <image-manipulation *ngIf="thumbnailType == 'enlarged-square'" [img]="isCloudFrontType == false ? item.thumbnail_square || item.thumbnail : item.thumbnail_square_cloudfront_url || item.thumbnail_cloudfront_url" [params]="assetImgParam" [notFound]="notFoundImg"></image-manipulation>
                                                <image-manipulation *ngIf="thumbnailType == 'enlarged-circle'" [img]="isCloudFrontType == false ? item.thumbnail_square || item.thumbnail : item.thumbnail_square_cloudfront_url || item.thumbnail_cloudfront_url" [params]="assetImgParam" [notFound]="notFoundImg"></image-manipulation>
    



                                                <div class="_visible_hover1">
                                                    <div class="_v_c_l"><a href="javascript:;">
                                                            <div class="_c_progress ng-star-inserted"><span
                                                                    class="ng-star-inserted">{{item.category_name}}

                                                                </span>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-container>

                        </owl-carousel-o>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>




<a data-toggle="modal" data-target="#addToWatchList" #addToWatchListSuccess></a>

<div class="modal auth" id="addToWatchList">
    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header" style="visibility: hidden;">
                <button type="button" id="closeAddToWatchList" class="close" data-dismiss="modal"
                    #removeToWatchListSuccessModal>&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <div class="success-add">
                    <img src="../../../assets/img/success.png">
                    <p id="itemAddSuccessMsg" #itemAddedIntoWach></p>

                </div>
            </div>
        </div>
    </div>
</div>







<!-- JW Player  -->
<ng-template #jwplaydom let-modal>
    <!-- <app-jw-video-player [streamInfo]="streamInfo"></app-jw-video-player> -->
    <app-videojs-player [streamInfo]="streamInfo"></app-videojs-player>
</ng-template>

<!-- Cancel subscription -->
<ng-template #content let-modal>
    <app-login></app-login>
</ng-template>