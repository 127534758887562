import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ChosePlanComponent } from './chose-plan.component';

import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        children: [
            { path: '', component: ChosePlanComponent }
        ]
    }
];

@NgModule({
  declarations: [ChosePlanComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes)
    
  ],
  exports: [RouterModule],
  providers:[]
})
export class ChosePlanModule { }
