import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



import { Routes, RouterModule } from '@angular/router';
import { ChoosePlanV2Component } from './choose-plan-v2.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
const routes: Routes = [
    {
        path: '',
        children: [
            { path: '', component: ChoosePlanV2Component }
        ]
    }
];

@NgModule({
  declarations: [ChoosePlanV2Component],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    NgbModule
    
  ],
  exports: [RouterModule],
  providers:[]
})
export class ChoosePlanV2Module { }
