import { Component, Input, OnInit} from '@angular/core';
import { Router } from '@angular/router';
// import { UserService } from 'src/app/core';
import { UserService } from "src/app/core/services/user.service";
import { CatlougeTypeConstants } from 'src/app/core/constants';
import { AssetsService } from "../../core/services/assets.service";
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from "src/app/core/services/common.service";


@Component({
  selector: 'app-campaign-banner',
  templateUrl: './campaign-banner.component.html',
  styleUrls: ['./campaign-banner.component.css']
})
export class CampaignBannerComponent implements OnInit {

  catlouge = CatlougeTypeConstants.catlogue;
  @Input() data: any = {};
  landingPage: any;
  episodeDeatil: any;
  imgType: any;
  showImg: any;
  width: string;
  bannerWidth: boolean;
  public userInfo: any;
  isRedir: boolean = false;
  isCloudFrontType: boolean = false;
  categoryImage: any;
  thumbnailBannerStretchedLandscape: any;
  thumbnail: any;



  constructor(private router: Router, 
              public UserService: UserService, 
              public AssetsService: AssetsService,
              private modalService: NgbModal,
              public commonService: CommonService) { }

  ngOnInit(): void {

    this.data = this.data;
    if(this.commonService.filterType ==1){
      this.isCloudFrontType = true ;
      this.categoryImage = this.data.category_image_cloudfront_url;
      this.thumbnailBannerStretchedLandscape = this.data.thumbnail_banner_stretched_landscape_cloudfront_url;
      this.thumbnail  = this.data.thumbnail_cloudfront_url;
    }else{
      this.isCloudFrontType = false;
      this.categoryImage = this.data.category_image;
      this.thumbnailBannerStretchedLandscape = this.data.thumbnail_banner_stretched_landscape;
      this.thumbnail  = this.data.thumbnail;
    }
    this.landingPage = this.data.landing_page;
    // this.imgType =this.data&&this.data.thumbnail_type&&this.data.thumbnail_type.slug?this.data.thumbnail_type.slug:'';
    this.imgType =this.data.thumbnail_type.slug;
    switch(this.imgType){
      case 'stretched-landscape':
        this.showImg = this.categoryImage;
        this.bannerWidth = true;
        break;
      case 'banner':
        this.bannerWidth =false;
        this.showImg = this.thumbnailBannerStretchedLandscape;
        break;
       default:
        this.showImg = this.thumbnail;
        break;
    }
  }


  redirectUrl(content) {
    this.userInfo = localStorage.getItem('userInfo');
    if(this.userInfo){
    switch (this.landingPage) {
      case '__url':
        this.landingUrl();
        break;
      case '__app_page':
        this.landingAppPage();
        break;
      case '__sub_category':
        this.landingSubCategory();
        break;
      case '__asset':
        this.landingAsset();
        break;
      default: this.router.navigateByUrl('/');
        break;

    }
  }else{
    //this.commonService.setBeforeLoginState(this.data.assets[0]);
    this.commonService.setBeforeLoginState(this.data);
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }
  }

  landingUrl() {
    let url = this.data.url;
    window.open(url, '_blank').focus();
  }
  landingAppPage() {
    let appPage = this.data.app_page;

    switch (appPage) {
      case 'about':
        this.router.navigateByUrl('/aboutus');
        break;
      case 'faq':
        this.router.navigateByUrl('/contactus');
        break;
      case 'help':
        this.router.navigateByUrl('/contactus');
        break;
      case 'my-profile':
        this.router.navigateByUrl('/myaccount');
        break;
      case 'theme-setting':
        this.router.navigateByUrl('/');
        break;
      case 'subscription':
        this.router.navigateByUrl('/subscribe/choseplan');
        break;
      case 'activation-code':
        this.router.navigateByUrl('/subscribe/choseplan');
        break;
      case 'watchlist':
        this.router.navigateByUrl('/playlist');
        break;

      default: this.router.navigateByUrl('/');
        break;

    }
  }
  landingSubCategory() {
    if (this.data.sub_category_id != '' || this.data.sub_category_id != undefined) {
      // let sub_category_id = this.data.sub_category_id;
      // let viewalltabDataParams = {
      //   "sub_category_id" :sub_category_id,
      //   "type" : 'home'
      // }

      let viewAlldParams = {
        categoryId: this.data.sub_category_id,
        // categoryType: this.data.category_type
      }

      viewAlldParams['type'] = this.data.tab_slug;
      // viewAlldParams['sub_category_id'] = this.data.sub_category_id;
      // viewAlldParams['banner'] = "campaign_banner";
      localStorage.setItem('banner','campaign_banner');
      // this.router.navigate(['viewall'],{queryParams:viewalltabDataParams});
      this.router.navigate(['/subcategory'], { queryParams: viewAlldParams })

    } else {
      let categoryId = this.data.category_id;
      let viewalltabDataParams = {
        banner: "campaign_banner",
        "categoryId" :categoryId,
        "type" : 'home'
      }

      this.router.navigate(['/subcategory'], { queryParams: viewalltabDataParams })

      // this.router.navigate(['viewall'],{queryParams:viewalltabDataParams});
    }
  }
  landingAsset() {
    let assetData = this.data.asset;
    this.router.navigate(['detail/' + this.data.asset.type + '/' + this.data.asset.code + '/' + this.data.asset.type])
    // code: "TSH338" type: "tvshow"
    // code: TSH336
    // catlogue: 5f48bc1eb9124f41a16c5712
    // platform: web
    // type: tvshow
  }














  // getAssetsData() {
  //   // if (this.tempQueryStr.type == 'episode' || this.tempQueryStr.type == 'tvshow') {
  //   //         this.getSeasonsData();
  //   //       } 
  //   let assetData = this.data.asset;
  //   console.log('getAssetsData');
  //   let assetDetail = {
  //     "code": assetData.code,
  //     "catlogue": this.catlouge,
  //     "platform": 'web',
  //     "type": assetData.type
  //   }
  //   let urlEncodedDetail = this.UserService.convertoJSONToUrlEncoded(assetDetail);
  //   setTimeout(()=>{

  //     this.AssetsService.getAssetDetail(urlEncodedDetail).subscribe(data => {
  //       //console.log(data.result);
  //       if (data && data.result) {

  //         if (data.error_code === 401) {
  //           window.location.replace('404')
  //         }

  //         if (Object.keys(data.result).length === 0) {
  //           document.getElementById("nexg_ngb-progressbar").style.display = 'none';
  //             document.getElementById('maincontentarea').classList.remove("_beforeLoad");
  //           // this.showError = true;
  //         }
  //         else{
  //           // if(data.result.recently_watched.stop == ""){
  //           //   data.result.recently_watched.stop = "0";
  //           // }
  //           this.episodeDeatil = data.result;

  //         }


  //         console.log('this.episodeDeatil',this.episodeDeatil);
  //         // if(this.seasonData.result || this.seasonData.result.length>0){
  //         //   this.episodeDeatil = this.seasonData.result;
  //         // }
  //         // this.episodeDeatil['type'] = this.episodeDeatil.type ? this.episodeDeatil.type : this.tempQueryStr.type
  //         if(this.episodeDeatil.recently_watched){
  //           this.episodeDeatil['stop'] = this.episodeDeatil.recently_watched.stop;

  //         }
  //         console.log(this.episodeDeatil['stop']);
  //         // let bgImage = this.tempQueryStr.type == 'episode' ? this.episodeDeatil?.tvshow_image : this.tempQueryStr.type == 'livetv' ? this.episodeDeatil.chimage : this.episodeDeatil.image;
  //        // let bgImage = this.tempQueryStr.type == 'episode' ? this.episodeDeatil?.tvshow_image : this.tempQueryStr.type == 'livetv' ? this.episodeDeatil.chimage:this.episodeDeatil.image;

  //         // this.episodeDeatil.director = ''
  //         // this.episodeDeatil.producer = ''
  //         // this.episodeDeatil.writer = ''
  //         // this.episodeDeatil.cast = ''
  //         if (this.episodeDeatil.name != undefined)
  //         //   this.pageTitleService.setTitle(this.episodeDeatil.name);
  //         // else
  //         //   this.pageTitleService.setTitle(this.episodeDeatil.channel_name);


  //         // this.googleEventTracking.routeTrack(this.commonService.detailRoute(this.episodeDeatil));

  //         // this.meta.updateTag({ property: 'og:url', content:document.URL});
  //         // this.meta.updateTag({ property: 'og:title', content:this.episodeDeatil.name });
  //         // this.meta.updateTag({ property: 'og:image', content: bgImage });
  //         // this.meta.updateTag({ property: 'og:description', content:  this.episodeDeatil.synopsis});

  //         // if (this.tempQueryStr.type == 'tvshow') {
  //         //   this.episodeDeatil.image = bgImage;
  //         // }
  //         setTimeout(() => {
  //           try {
  //             console.log(this.episodeDeatil);
  //             try {
  //               if(this.episodeDeatil.recently_watched.season && this.episodeDeatil.recently_watched.season.season_number && this.episodeDeatil.recently_watched.no) {
  //                 // this.changeSeason(this.episodeDeatil.recently_watched.season.season_number, (this.episodeDeatil.recently_watched.season.season_number));
  //               }
  //             } catch(e){
  //               console.log(e);
  //             }
  //             // bgImage = this.commonService.compressImgUrl(bgImage, { 'width': 1280, 'height': 720 });
  //             // let bgImage = this.tempQueryStr.type == 'episode' ? this.episodeDeatil?.tvshow_image : this.tempQueryStr.type == 'livetv' ? this.episodeDeatil.chimage:this.episodeDeatil.image;
  //             // bgImage = this.commonService.compressImgUrl(bgImage, {'width':1280,'height':720});
  //           //  console.log(bgImage);
  //             // document.getElementById('detail_banner').style.backgroundImage = "url(" + bgImage + ")";

  //             document.getElementById("nexg_ngb-progressbar").style.display = 'none';
  //             document.getElementById('maincontentarea').classList.remove("_beforeLoad");


  //           }
  //           catch (e) {
  //             console.log(e)
  //           }

  //         }, 200)

  //         // if (this.tempQueryStr.type == 'episode' || this.tempQueryStr.type == 'tvshow') {
  //         //   this.getSeasonsData();
  //         // }         
  //         // recommended Section 
  //         let recommededParams = {
  //           code: this.episodeDeatil.type == 'livetv' ? this.episodeDeatil.code : this.episodeDeatil.recently_watched.code,
  //           search_text: '',
  //           catlogue: this.catlouge
  //         }
  //         // this.getSeasonsData();
  //         // this.recomData(recommededParams);


  //       }

  //     });
  //   }, 2000)


  // }

  // recomData(recommededParams) {
  //   setTimeout(() => {
  //     this.spinner.hide();
  //   }, 50);
  //   this.loading = true;
  //   let urlEncodedRecomdedParams = this.UserService.convertoJSONToUrlEncoded(recommededParams);
  //   this.AssetsService.getRecommended(urlEncodedRecomdedParams).subscribe(data => {
  //     this.recommendedData = data;
  //       if (this.recommendedData.result) {
  //         this.getWatchList();

  //         this.recommendedData.result.forEach(ele => {
  //           if (ele.type == "episode") {
  //             if (!this.episodeArr.find(epiObj => epiObj.name == ele.ss)) {
  //               this.episodeArr.push({ name: ele.ss })
  //             }
  //             this.firstSelectedEpisode = this.episodeArr[0].name;
  //           } 


  //         });
  //       }
  //   });

  // }

}
